'use strict';

(function ($) {
  'use strict';

  $ = $ && $.hasOwnProperty('default') ? $['default'] : $;

  var $window = $(window);
  var $html = $('html');
  var $body = $('body');

  var CLASS_NOSCROLL = 'noscroll';

  var CLASS_NAV_OPEN = 'SiteHeader--open';
  var CLASS_HEADER_SLIM = 'SiteHeader--slim';
  var BP_LARGE = 1024;
  var BP_WIDE = 1400;

  var $siteHeader = $('.js-site-header');
  var $siteHeaderNav = $siteHeader.find('.js-navigation');
  var $siteHeaderNavMenu = $siteHeaderNav.find('.js-menu, .js-menu-cta');
  var $siteHeaderNavCircle = $siteHeaderNav.find('.js-circle');
  var $toggle = $siteHeader.find('.js-navigation-toggle');
  var $onepagenavclick = $('.SiteHeaderNavigation-menu .menu-item');
  var resized = window.innerHeight;

  $window.on('scroll', toggleSlimHeader);
  $window.on('resize', hideHeaderOnResize);
  $toggle.on('click', toggleNavigation);
  $onepagenavclick.on('click', closeNavigation);

  function toggleSlimHeader() {
    var canToggleSlim = $window.scrollTop() > $siteHeader.outerHeight() && !$html.hasClass(CLASS_NOSCROLL);
    $siteHeader.toggleClass(CLASS_HEADER_SLIM, canToggleSlim);
  }

  function closeNavigation() {
    if (isNavigationOpen()) {
      $siteHeader.removeClass(CLASS_NAV_OPEN);
      hideMenu();
    }
  }

  function hideHeaderOnResize() {
    if (window.innerHeight === resized && isNavigationOpen()) {
      $siteHeader.removeClass(CLASS_NAV_OPEN);
      hideMenu();
    }
    resized = window.innerHeight;
  }

  function toggleNavigation() {
    $siteHeader.toggleClass(CLASS_NAV_OPEN);
    if (isNavigationSlim() && $window.width() < BP_LARGE) $siteHeader.removeClass(CLASS_HEADER_SLIM);
    isNavigationOpen() ? showMenu() : hideMenu();
  }

  function showMenu() {
    var size = calcHypotenuse($window.width(), $window.height()) * 2;
    var offset = $('.js-site-header-inner').outerHeight();
    var css = {
      'width': size,
      'height': size,
      'margin-top': -(size / 2),
      'margin-right': -(size / 2)
    };

    $html.toggleClass(CLASS_NOSCROLL, $window.width() < BP_WIDE);
    $siteHeaderNav.addClass('is-transitioning').css('min-height', 'calc(100% - ${offset}px)');
    $siteHeaderNavMenu.addClass('is-transitioning');
    $siteHeaderNavCircle.show().css(css).velocity({ scale: [1, 0] }, {
      duration: 500,
      complete: function complete() {
        $siteHeaderNav.removeClass('is-transitioning').addClass('is-present');
        $siteHeaderNavMenu.removeClass('is-transitioning').addClass('is-present').velocity({ opacity: [1, 0] }, 300);
        $siteHeaderNavCircle.hide();
      }
    });
  }

  function hideMenu() {
    $siteHeaderNav.addClass('is-transitioning');
    $siteHeaderNavMenu.addClass('is-transitioning').velocity({ opacity: [0, 1] }, {
      duration: 300,
      complete: function complete() {
        $siteHeaderNav.removeClass('is-present');
        $siteHeaderNavMenu.removeClass('is-present');
        $siteHeaderNavCircle.show().velocity({ scale: [0, 1] }, {
          duration: 500,
          complete: function complete() {
            $html.removeClass(CLASS_NOSCROLL);
            $siteHeaderNav.removeClass('is-transitioning').css('min-height', '');
            $siteHeaderNavMenu.removeClass('is-transitioning');
          }
        });
      }
    });
  }

  function isNavigationOpen() {
    var className = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : CLASS_NAV_OPEN;

    return $siteHeader.hasClass(className);
  }

  function isNavigationSlim() {
    var className = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : CLASS_HEADER_SLIM;

    return $siteHeader.hasClass(className);
  }

  function calcHypotenuse(a, b) {
    return Math.sqrt(a * a + b * b);
  }

  function debounce(fn) {
    var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;

    var timer = null;

    return function () {
      var context = this;
      var args = arguments;

      clearTimeout(timer);
      timer = setTimeout(function () {
        return fn.apply(context, args);
      }, delay);
    };
  }
})(jQuery);